import api from "../../utils/api"

// 科室列表
export async function get_departments(payload) {
  return api.get(`/api/v1/operation/crowd/departments`, { params: payload })
}
// 列表
export const get_list_api = (params) => api.post("/api/v1/operation/video/page", params)

export const detail_video_api = (params) => api.get(`/api/v1/operation/video/getByVideoNo`, { params })

// 增加视频播放量
export const play_count = (params) => api.post("/api/v1/operation/video/play", params) // video_no count

// 播放时长记录
export const play_times = (params) => api.post("/api/v1/operation/video/play_time/record", params) // video_no count play_session_code
