<template>
  <div class="page">
    <van-dropdown-menu>
      <van-dropdown-item
        title="分类"
        v-model="form.type_code"
        :options="deps"
        @change="typeChange"
      />
    </van-dropdown-menu>
    <!-- @scroll="onScroll" -->
    <div class="common-video-list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="已经到底啦~"
        @load="onLoad"
        v-if="list.length"
      >
        <template slot="loading">
          <van-loading type="spinner" size="24px" />
        </template>
        <div class="video-wrapper" v-if="list.length !== 0">
          <div
            class="common-video-list-item"
            @click="toPlay(el)"
            v-for="el in list"
            :key="el.id"
          >
            <div class="cover_wrapper">
              <img
                class="cover"
                src="../../assets/img/article/video-def.png"
                alt=""
                v-if="!el.cover"
              />
              <div
                class="video-cover"
                :style="{
                  backgroundImage: `url(${articleMediaHost}/${el.cover})`,
                }"
                v-else
              />
              <div class="info">
                <div class="left">
                  <img src="../../assets/img/article/play.png" alt="" />
                  <span>{{ toLocaleString(el.play_count) }}</span>
                </div>
                <div class="right">{{ format_duration(el.times) }}</div>
              </div>
            </div>
            <div class="video-title">
              <span>{{ el.describe }}</span>
            </div>
          </div>
        </div>
        <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->
      </van-list>

      <div v-if="!list.length && !loading" class="no-data">暂无数据</div>
    </div>
  </div>
</template>

<script>
import { get_list_api, get_departments } from "./service"
import { format_duration } from "@/utils/format"
// import { Notify } from "vant"

const form = {
  page: 0,
  page_size: 10,
  type_code: "",
  type_remark: 2,
}

// let timer = null
export default {
  data() {
    return {
      list: [],
      form: { ...form },
      total: 0,
      articleMediaHost: GLOBAL_CONFIG.articleMediaHost,
      deps: [],
      loading: null,
      finished: false,
    }
  },
  created() {
    this.get_list()
    this.get_departments()
  },
  methods: {
    typeChange() {
      this.form.page = 0
      this.total = 0
      this.list = []
      this.get_list()
    },
    async get_departments() {
      const { data } = await get_departments()

      this.deps = [
        { text: "全部", value: "" },
        ...data.departments?.map((el) => ({
          text: el.name,
          value: el.code,
        })),
      ]
    },
    format_duration,
    toLocaleString(num = 0) {
      if (!num) return 0
      if (num >= 10000) {
        return +(num / 10000).toFixed(1) + "万"
      }
      return num.toLocaleString()
    },
    onLoad() {
      this.form.page += 1
      this.get_list()
    },
    // onScroll(e) {
    //   if (
    //     e.target.scrollHeight - (e.target.scrollTop + e.target.clientHeight) <
    //     80
    //   ) {
    //     timer && clearTimeout(timer)
    //     timer = setTimeout(() => {
    //       this.form.page += 1
    //       this.get_list()
    //     }, 300)
    //   }
    // },
    async get_list() {
      this.loading = true
      if (
        this.form.page * this.form.page_size < this.total ||
        this.total === 0
      ) {
        const { data } = await get_list_api(this.form)
        this.list.push(...data.data)
        this.total = data.total_elements
        if (this.list?.length >= data.total_elements) {
          this.finished = true
        }
      } else {
        // Notify({ message: "已经加载完啦", type: "warning" })
      }
      this.loading = false
    },
    toPlay(row) {
      // 埋点

      this.$router.push({
        path: "/video",
        query: {
          ...this.$route.query,
          video_no: row.video_no,
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.page {
  background: #f9fcff;
  height: 100%;
}
.common-video-list {
  padding: 16px 16px 0 16px;
  overflow-y: auto;
  height: calc(100% - 68px);
  .video-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .common-video-list-item {
      width: calc((100% - 12px) / 2);
      margin-bottom: 16px;
      display: flex;
      flex-direction: column;
    }
    .video-title {
      font-size: 14px;
      color: #000000;
      line-height: 20px;
      padding: 8px;
      background: #fff;
      border-radius: 0px 0px 4px 4px;
      flex: 1;
      margin: 0;
      overflow: hidden;
      span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-height: 20px;
      }
    }
    .cover_wrapper {
      position: relative;

      box-shadow: 0px 0px 8px 0px rgba(179, 186, 196, 0.07);
      border-radius: 4px 4px 0 0;
      height: 112px;
      overflow: hidden;

      .cover {
        position: absolute;
        width: 86px;
        height: 86px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .video-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      .info {
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0) 0%,
          rgba(47, 47, 47, 0) 78%,
          rgba(54, 54, 54, 0.29) 88%,
          rgba(0, 0, 0, 0.38) 100%
        );
        position: absolute;
        bottom: 0px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        color: #fff;
        font-size: 10px;
        padding: 0 10px;
        padding-top: 100px;
        padding-bottom: 4px;

        img {
          width: 12px;
          margin-right: 4px;
        }
      }
    }
    .left {
      display: flex;
      align-items: center;
      img {
        margin-right: 4px;
      }
    }
  }
}
.no-data {
  padding: 40px 0;
  text-align: center;
}
</style>

<style >
.van-dropdown-item__option--active,
.van-dropdown-item__option--active .van-dropdown-item__icon {
  color: #377dff;
}
.van-dropdown-menu__title--active {
  color: #323233;
}
</style>